.banner-widget {
    $bannerWidget: &;
    padding-bottom: 24px;
    padding-top: 24px;

    @media #{$media-xs} {
        padding-bottom: 12px;
        padding-top: 12px;
    }

    &__header {
        text-align: center;

        @media #{$media-xs} {
            text-align: left;
        }
    }

    &__title {
        color: #2f3146;
        font-weight: 600;
        font-size: 32px;
        line-height: 1.1;
        margin-bottom: 25px;

        @media #{$media-xs} {
            font-size: 16px;
            margin-bottom: 20px;
        }
    }

    &__slider {
        order: 0;
        flex: 1 1 auto;
        border-radius: 12px;
        padding: 0 0 32px;

        @media #{$media-xs} {
            padding: 0 0 24px;
        }
    }

    &__slider:not(.glider) {
        display: flex;
        justify-content: center;

        #{$bannerWidget}__item:nth-of-type(1n + 4) {
            display: none;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__item {
        display: flex;
        justify-content: center;
        margin: 0 10px;

        @media #{$media-xs} {
            margin: 0 20px 0 0;
        }

        img {
            border-radius: 12px;
            box-shadow: 0px 2px 12px rgba(183, 189, 196, 0.5036);
        }
    }

    &__image {
        max-width: 100%;
        height: 135px;
        width: 240px;
        &--small {
            display: none;
        }

        &--medium {
            display: block;
        }

        @media #{$media-to-sm} {
            &--small {
                display: block;
            }

            &--medium {
                display: none;
            }
        }
    }

    &__prev {
        order: -1;
        margin-right: 30px;
    }

    &__next {
        order: 1;
        margin-left: 30px;
        transform: rotate(180deg);
    }

    &__prev,
    &__next {
        min-width: 56px !important;
        min-height: 60px !important;
        background-color: $white;
        border: 1px solid $sherpa-blue;
        border-radius: 50%;

        &:hover,
        &:focus,
        &:active {
            background-color: inherit !important;
        }

        &:hover {
            border-color: $green-hover!important;

            svg{
                path{
                    stroke: $stroke-hover;
                }
            }
        }

        .icon {
            height: 22px;
        }
    }

    @media (max-width: 767px) {
        &__prev,
        &__next {
            display: none;
        }
    }

    .c-product-category__more {
        font-weight: 600;
        position: initial;
        margin: 0 auto;
        display: flex;
        color: #007F32;
        font-size: 16px;
        line-height: 28px;
        width: fit-content;

        @media #{$media-xs} {
            font-size: 14px;
            line-height: 22px;
        }

        &::after {
            content: '';
            -webkit-mask-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m22.32 11.71-5.44-5.43a.4.4 0 0 0-.57 0c-.16.16-.16.41 0 .57l4.76 4.76H1.97c-.22 0-.4.18-.4.4s.18.4.4.4h19.09l-4.76 4.76c-.16.16-.16.41 0 .57.08.08.18.12.28.12s.21-.04.28-.12l5.44-5.45c.16-.16.16-.41 0-.57z" fill="%23fff"/></svg>');
            mask-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m22.32 11.71-5.44-5.43a.4.4 0 0 0-.57 0c-.16.16-.16.41 0 .57l4.76 4.76H1.97c-.22 0-.4.18-.4.4s.18.4.4.4h19.09l-4.76 4.76c-.16.16-.16.41 0 .57.08.08.18.12.28.12s.21-.04.28-.12l5.44-5.45c.16-.16.16-.41 0-.57z" fill="%23fff"/></svg>');
            width: 24px;
            height: 24px;
            position: absolute;
            margin-left: 8px;
        }

        .link-arrow__icon {
            margin-top: -2px;
            margin-left: 5px;
            height: 19px;
        }
    }


}
