.box-widget-category-product {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
    padding: 24px;

    @media #{$media-xs} {
        border-left: none;
        border-right: none;
        border-radius: unset;
        padding: 12px;
        margin: 0 -15px;
    }

    .widget-category-product {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 16px;
        max-width: 1730px;
        margin: 0 auto;

        @media #{$media-to-md} {
            flex-direction: column;
            max-width: 100%;
        }

        &__aside {
            display: flex;
            flex-wrap: wrap;
            max-width: 330px;
            width: 100%;
            background-color: #007F32;
            border: 1px solid #007F32;
            border-radius: 16px;
            padding: 16px;
            gap: 16px;
            color: #fff;
            align-content: baseline;
            flex-direction: column;

            @media #{$media-to-md} {
                flex-direction: column;
                max-width: 100%;
                gap: 8px;
            }

            .js-tabs-header {
                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            h2 {
                font-size: 24px;
                font-weight: 600;
                line-height: 36px;
                margin-bottom: 40px;

                @media #{$media-to-md} {
                    font-size: 20px;
                    line-height: 30px;
                    margin-bottom: 0;
                }
            }
            h3 {
                font-size: 16px;
                font-weight: 400;
                line-height: 28px;
                margin-bottom: unset;

                @media #{$media-to-md} {
                    font-size: 14px;
                    line-height: 22px;
                    width: 45%;
                }
            }
        }

        &__carousel {
            max-width: 1344px;
            min-width: 0;


            .c-tabs__content {
                padding-top: 0;
                position: relative;
                width: 100%;
                background-color: unset;

                .c-product-category__wrapper {
                    @media #{$media-to-md} {
                        margin-right: -20px;
                    }
                }

                &.is-active {
                    padding-top: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 24px;

                    @media #{$media-to-md} {
                        gap: 0;
                        padding-left: 0;
                        padding-right: 0;
                    }

                    .product-collection__btn{
                        display: block;

                        @media #{$media-to-md} {
                            display: none;
                        }
                    }
                }

                .product-collection__btn {
                    display: none;

                    &::after {
                        width: 56px;
                        height: 60px;
                        border-radius: 50px;
                        margin-top: -30px;
                        margin-left: -29px;
                    }

                    path {
                        stroke: $color-text-headings;
                    }


                    &--left {
                        left: 18px;
                        transform: rotate(180deg);
                    }

                    &--right {
                        right: 18px;
                    }

                    .icon {
                        width: 24px;
                        height: 24px;
                    }
                    &:hover {
                        &:after {
                            @include n-theme() {
                                border-color: $green-hover;
                            }
                        }

                        svg {
                            path {
                                stroke: $stroke-hover;
                            }
                        }
                    }

                    &.disabled:hover {
                        &:after {
                            border-color: $sherpa-blue-light;
                        }
                    }
                }

                .c-product-category__slider {
                    max-width: 1344px;

                    .c-product-category__item {
                        display: flex;

                        @media #{$media-to-md} {
                            justify-content: left;
                        }
                    }

                    .glider-track {
                        gap: 10px
                    }
                }

                .c-product-category__more {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 28px;
                    margin-bottom: unset;
                    color: #007f32;
                    position: relative;
                    margin-top: unset;
                    display: flex;
                    justify-content: center;

                    @media #{$media-to-md} {
                        font-size: 14px;
                        line-height: 21px;
                    }

                    .link-arrow__icon {
                        max-height: 24px;
                    }
                }

                .title-section {
                    display: flex;
                    justify-content: space-between;

                    h3 {
                        line-height: 40px;
                        font-size: 28px;
                        font-weight: 600;
                        flex: 1;
                        max-width: 60%;

                        @media #{$media-to-md} {
                            font-size: 14px;
                            line-height: 22px;
                        }
                    }
                }

                .prod-box {
                    max-width: 336px;
                    height: 556px;
                    padding: 24px;
                    border-radius: 16px;
                    border: 1px solid #cee7d8;
                    box-shadow: 0 4px 8px rgba(47,49,70,.1);
                    margin: unset;

                    @media #{$media-sm} {
                        height: auto;
                    }

                    @media #{$media-xs} {
                        padding: 7px 5px;
                        max-width: 152px;
                        height: 397px;
                        margin: 0;
                    }

                    &__main-link {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                        margin: 0 25px;
                        height: 80%;
                    }
                    &--product-of-the-week {
                        border: 1px solid #e83968;
                        border-radius: 10px;
                    }

                    &__img {
                        position: relative;
                        display: flex;
                        min-height: 0;
                        height: 246px;
                        width: auto;
                        border-bottom: none;
                        padding-bottom: 0;
                        padding-top: 0;
                        @media #{$media-xs} {
                            height: 136px;
                            padding-top: 0;
                        }

                        .prod-box__labels {
                            left: -6px;
                            top: 0;

                            @media #{$media-xs} {
                                left: -4px;
                                top: -3px;
                                max-width: 74px;
                                font-size: 10px;

                                a {
                                    height: 24px;
                                    max-width: 95px;
                                    padding: 0;
                                }
                            }
                        }

                        img {
                            max-width: 216px !important;
                            max-height: 216px;

                            @media #{$media-xs} {
                                max-width: 136px !important;
                                max-height: 136px;
                            }
                        }

                        strong {
                            position: absolute;
                            bottom: 10px;
                        }
                    }
                    &__name {
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 28px;
                        padding-bottom: 10px;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        height: 3lh;

                        @media #{$media-xs} {
                            margin-bottom: 4px;
                            font-size: 14px;
                            line-height: 21.7px;
                            height: 3lh;
                            padding: 0 8px;
                        }
                    }
                    &__type {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 18px;
                        height: 2lh;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                        overflow: hidden;

                        @media #{$media-xs} {
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 18px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            text-transform: lowercase;
                            padding: 0 8px;
                        }
                    }

                    &__price {
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 34px;
                        margin-top: 25px;

                        @media #{$media-to-sm} {
                            font-size: 16px;
                            padding: 0 8px;
                            margin-top: 0;
                        }
                    }
                    &__btns {
                        display: flex;
                        flex-direction: row;
                        height: 48px;
                        min-height: 32px;
                        margin: 8px 0;
                        padding: 0;
                        position: relative;

                        @media #{$media-xs} {
                            height: auto;
                            margin: 0;
                            padding: 0 8px;
                        }

                        form {
                            flex-basis: 75%;
                            padding-left: 0;
                            padding-right: 0;
                            padding-top: 0;
                        }

                        .prod-box__btn {
                            &:hover {
                                color: $green-hover;

                                svg{
                                    path{
                                        stroke: $stroke-hover;
                                    }
                                }
                            }


                            @media #{$media-to-sm} {
                                max-width: 130px;
                                min-height: 40px;
                                height: 40px;
                                font-size: 10px;
                                line-height: 12px;
                                padding: 5px;
                                width: 100%;

                                &::after {
                                    width: 18px;
                                    mask-size: 18px;
                                    top: 9px;
                                    right: 5px;
                                }
                            }
                        }

                        .product-shop__link--wishlist {
                            top: 0;
                            right: 2px;
                            bottom: 96px;
                            align-items: center;
                            background: transparent;
                            border-radius: 50%;
                            display: flex;
                            min-height: 40px;
                            height: 40px;
                            justify-content: center;
                            width: 40px;
                            @media #{$media-xs} {
                                background: #fff;
                            }
                            &.wishlist-added {
                                .product-options__icon {
                                    background: none;
                                    path {
                                        fill: $sherpa-blue;
                                        stroke: none;
                                    }
                                }
                            }

                            &:hover {
                                .product-options__icon {
                                    background: none;
                                    path{
                                        fill: $sherpa-blue;
                                        stroke: none;
                                    }
                                }
                            }

                            &:focus {
                                .product-options__icon {
                                    background: none;
                                    path{
                                        fill: $sherpa-blue;
                                        stroke: none;
                                    }
                                }
                            }

                            svg path{
                                fill: #2F3146;
                            }

                            @media #{$media-to-sm} {
                                right: -5px;
                                top: -282px;
                            }
                        }

                        form {
                            flex-basis: 75%;
                            padding-left: 0;
                            padding-right: 0;

                            @media #{$media-xs} {
                                padding-top: 0;
                                flex-basis: 100%;
                            }
                        }
                    }

                    .tooltip-max-price-info {
                        margin: 0;
                        padding: 0;

                        p {
                            font-size: 10px;
                        }

                        @media #{$media-to-sm} {
                            padding: 0 0 16px;
                            p {
                                margin: 8px 0;
                                font-weight: 400;
                                line-height: 15px;
                            }
                        }
                    }
                }
            }
        }

        .icon-more-right-arrow {
            color: #fff;
            font-size: 16px;
            font-weight: 600;
            line-height: 28px;
            margin-top: 26px;

            @media #{$media-to-md} {
                font-size: 14px;
                line-height: 21px;
                margin-top: unset;
            }

            &:after {
                background-color: #fff;
                content: "";
                height: 24px;
                margin-left: 8px;
                -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m22.32 11.71-5.44-5.43a.4.4 0 0 0-.57 0c-.16.16-.16.41 0 .57l4.76 4.76H1.97c-.22 0-.4.18-.4.4s.18.4.4.4h19.09l-4.76 4.76c-.16.16-.16.41 0 .57.08.08.18.12.28.12s.21-.04.28-.12l5.44-5.45c.16-.16.16-.41 0-.57z' fill='%23fff'/%3E%3C/svg%3E");
                mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m22.32 11.71-5.44-5.43a.4.4 0 0 0-.57 0c-.16.16-.16.41 0 .57l4.76 4.76H1.97c-.22 0-.4.18-.4.4s.18.4.4.4h19.09l-4.76 4.76c-.16.16-.16.41 0 .57.08.08.18.12.28.12s.21-.04.28-.12l5.44-5.45c.16-.16.16-.41 0-.57z' fill='%23fff'/%3E%3C/svg%3E");
                position: absolute;
                width: 24px;
            }
        }
    }
}

/* NEW HOMEPAGE - styles to remove or edit after all widgets merge
------------------------------------------------------------------------ */
.std,
.homepage {
    .special-offer-slider,
    .c-product-categories,
    .article-section {
        padding-right: 0;
        padding-left: 0;

        @media #{$media-to-xl} {
            padding-right: 0;
            padding-left: 0;
            margin-left: 0;
        }
    }

    .cms-adbanner {
        margin-bottom: 30px;
    }
}
