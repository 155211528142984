/* Chat widget
-------------------------------------------------------------- */
.product-chat {
    background: #fff;
    padding: 32px 38px;
    margin: 20px auto;
    border-radius: 12px;
    max-width: 1072px;

    @media #{$media-xs} {
        padding: 24px;
        margin: 32px auto;
        max-width: 336px;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;

        @media #{$media-xs} {
            display: block;
            max-width: 500px;
            margin: 0 auto;
        }
    }

    &__data {
        margin-right: 90px;

        @media #{$media-to-md} {
            margin-right: 30px;
        }

        @media #{$media-sm} {
            margin-right: 50px;
        }

        @media #{$media-xs} {
            margin-right: 0;
        }
    }

    &__title {
        font-size: 28px;
        font-weight: $font-weight-medium;
        color: $blue-dianne;
        text-align: start;
        margin: 0 auto 16px;
        line-height: 40px;

        @media #{$media-xs} {
            font-size: 16px;
            line-height: 24px;
            max-width: 400px;
            margin-bottom: 16px;
            font-weight: 600;
        }
    }

    &__text {
        max-width: 500px;

        @media #{$media-sm} {
            max-width: 360px;
        }
    }

    &__content {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        max-width: 850px;
        margin: 0 auto;

        @media #{$media-xs} {
            max-width: 360px;
            align-items: center;
            justify-content: center;
        }
    }

    &__list {
        padding-left: 5px;
        font-size: 18px;
        line-height: 28px;
        margin-left: 44px;

        @media #{$media-xs} {
            font-size: 14px;
            line-height: 18px;
            margin: 0 16px;
        }

        li {
            list-style-type: disc;
        }
    }

    &__img {
        margin-left: 0px;
        margin-top: 0px;


        @media #{$media-xs} {
            margin-top: 24px;
        }

        img {
            border-radius: 12px;
            max-width: 100%;
            max-height: 100%;
            margin: 0 auto;
            width: 220px;
            height: 220px;

            @media #{$media-xs} {
                width: 160px;
                height: 160px;
            }
        }

        .person {
            margin-top: 12px;
            text-align: center;

            @media #{$media-xs} {
                margin: 0 auto;
                max-width: 248px;
            }
        }

        .name {
            color: $blue-dianne;
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            margin: 0;
            margin-bottom: 12px;

            @media #{$media-xs} {
                font-size: 12px;
                line-height: 16px;
                margin-bottom: 6px;
            }
        }
    }

    &__action {
        display: flex;
        align-items: center;
        margin-top: 16px;

        @media #{$media-xs} {
            padding: 0px;
            flex-direction: column;
            margin-top: 16px;
        }
    }

    .chat-info {
        .chat-trigger {
            padding: 9px 20px;
            min-height: auto;
            font-size: 16px;
            line-height: 22px;
            text-transform: none;
            border-radius: 32px;
        }

        &__state {
            color: $red;
            display: flex;
            align-items: center;
            font-size: 16px;
            justify-content: center;

            @media #{$media-xs} {
                font-size: 12px;
            }

            span {
                background: $red-background;
                color: $red-dark2;
                font-size: 12px;
                padding: 6px 12px;
                line-height: 12px;
                border-radius: 32px;
                display: inline-block
            }

            &--online {
                color: #000;

                span {
                    background: $sherpa-blue-light;
                    color: $sherpa-blue;
                }
            }
        }
    }
}

.std {
    .product-chat {
        &__list {
            margin-left: 54px;
            margin-bottom: 0;

            @media #{$media-xs} {
                font-size: 13px;
                margin-left: 125px;
            }

            li {
                margin-bottom: 0;
            }
        }
    }
}
