/* =SLIDER MAIN
-------------------------------------------------------------- */
.ratio-box.container-default{
    .slider-main--desktop{
        margin: 0 15px;
        .glider {
            padding-bottom: 0;
        }
        .glider-slide img {
            width: 100%;
        }
        .slider-main__products{
            .prod-box {
                .btn {
                    img{
                        width: 70%;
                    }
                }
            }
        }
        @media #{$media-xs} {
            margin: 0;
        }
        @media #{$media-to-xl} {
            .slider-main__products{
                top:8px;
                .prod-box {
                    margin-bottom: 8px;
                }
            }
        }
    }
}


.titles-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
}

.slider-main {
    $b: &;
    position: relative;
    width: 100%;
    margin: 24px 0 0px;
    padding-bottom: 0px;

    @media #{$media-to-md} {
        margin: 0 0px;
        padding-bottom: 0;
    }

    @media #{$media-xs} {
        margin: 0px;
        padding-bottom: 0px;
    }

    &--loading {
        overflow-y: hidden;

        @media #{$media-xs} {
            padding-top: 47.7707006369427%;
            .ratio-box {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
            }
        }

        @media (max-width: 480px) {
            padding-top: 71.2074303405573%;
        }
    }

    @media #{$media-xs} {
        height: auto;

        .slick-list {
            height: auto;
        }
    }

    img {
        object-fit: cover;
    }

    &__item {
        position: relative;
    }

    /* =SLIDER PAGINATION DOTS
    -------------------------------------------------------------- */
    &__dots {
        position: absolute;
        bottom: -30px;
        z-index: 1;
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
        display: inline-flex;
        flex-wrap: nowrap;

        //@media #{$media-sm-up} {
        @media only screen and (min-width: 1025px) {
            display: none;
            bottom: 20px;
        }

        .glider-dot {
            display: inline-block;
            width: $glider-width;
            height: $glider-height;
            margin: $glider-margin;
            min-width: $glider-min-width;
            min-height: $glider-min-height;
            border-radius: $glider-border-radius;
            background: $glider-dot;
            cursor: pointer;

            @media screen and (max-width: 400px) {
                margin: 0 4px;
            }

            &:hover,
            &:focus,
            &:active {
                background: $glider-current-dot;
            }

            &.active {
                background: $glider-current-dot;
                width: $glider-current-width;
                margin: $glider-current-margin;
            }
        }
    }

    /* =SLIDER TITLES
    -------------------------------------------------------------- */

    &__titles {
        display: flex;
        justify-content: flex-start;
        min-width: inherit;
        width: auto;
        margin: 16px auto 0;
        overflow: hidden;
        overflow-x: scroll;

        @media #{$media-to-sm} {
            margin-top: 24px;
        }
    }

    &__titles {
        display: flex;
        justify-content: flex-start;
        margin: 30px auto 0;
        min-width: inherit;
        overflow-x: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
        scrollbar-color: #c0c0c0 transparent;
        width: auto;

        @media #{$media-xs} {
            margin-top: 16px;
        }

    }

    &__titles::-webkit-scrollbar {
        height: 8px;
        background: transparent;
        display: none;
    }

    &__titles::-webkit-scrollbar-thumb {
        background-color: #c0c0c0;
        border-radius: 4px;
        border: 2px solid transparent;
        background-clip: padding-box;
    }

    &__titles::-webkit-scrollbar-thumb:hover {
        background-color: #a0a0a0;
    }

    &__title-btn {
        padding: 0 15px 0 0;
        font-weight: 400;
        display: inline-flex;
        width: initial;
        min-width: fit-content;
        min-height: unset;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        color: $slide-text-color;
        text-transform: none;

        @media #{$media-lg} {
            padding: 20px 11px;
        }

        @media #{$media-xs} {
            padding-top: 0;
            font-size: 12px;
        }

        &::after {
            content: attr(data-text);
            content: attr(data-text) / '';
            height: 0;
            visibility: hidden;
            overflow: hidden;
            user-select: none;
            pointer-events: none;
            font-weight: 500;

            @media speech {
                display: none;
            }
        }
        &.current-slide {
            color: $current-slide-text-color;
        }

        &:focus {
            outline: 0;
            outline-offset: -1px;
        }

        &:hover {
            color: $current-slide-text-color;
        }
    }

    /* =SLIDES CONTAINER
    -------------------------------------------------------------- */
    &__slides {
        height: 502px;
        width: 100%;
        max-height: 502px;
        border-radius: 12px;
        box-shadow: inset 0px 0px 12px 4px rgba(183, 189, 196, 0.5036);

        @media #{$media-to-xl} {
            height: initial;
        }

        @media #{$media-md} {
            height: initial;
            max-height: 400px;
        }

        @media #{$media-sm} {
            max-height: 266px;
        }

        @media screen and (max-width: 400px) {
            max-height: 238px;
        }

        @media screen and (max-width: 400px) {
            max-width: 328px;
        }
    }

    .glider-slide {
        position: relative;
    }

    /* =SLIDE PRODUCTS
    -------------------------------------------------------------- */

    &__products {
        position: absolute;
        top: 42px;
        right: 20px;
        z-index: 9;

        @media #{$media-to-sm} {
            display: none;
        }

        .product-qty {
            max-width: 82px;
            height: 24px;
            display: flex;
            border: none;
            justify-content: space-between;
            padding: 0;
            margin: 0 7px 0 0;
            position: relative;

            input {
                width: 15px;
                height: 26px;
                background: transparent;
                font-size: 14px;
                color: $gray-base;
                font-weight: $font-weight-bold;
                line-height: 1;
                padding: 0;
                border: 0;
                font-style: normal;
                border-radius: 0;
                text-align: center;
                margin: -1px 4px 0 4px;
            }

            .btn {
                margin: 2px;
                height: 20px;
                font-weight: 300;
                width: 20px;
                border: none;
                background-color: $white;
                font-size: 18px;
                color: #CDD3D3;


                &:hover,
                &:focus {
                    background: transparent;
                }
            }

            .btn.active {
                svg {
                    path {
                        fill: #007F32;
                    }
                }
            }

            .qty-form__btn-up {
                background: $white;
                width: 20px;
                min-width: 20px;
                height: 20px;
                border: none;
                color: $sherpa-blue;

                &:hover,
                &:focus {
                    background: #fff;
                }
            }

            .max-qty-tooltip {
                position: absolute;
                left: 25px;
                width: 5px;
                margin: 0 auto;
            }

            &[data-qtyFormRestriction] {
                .btn {
                    display: none;
                }

                input {
                    width: 68px;
                }

                .tooltip-icon {
                    position: absolute;
                    opacity: 0;
                    width: 68px;
                }
            }
        }
    }

    &__product {
        &:nth-child(n+3) {
            display: none;
        }

        display: block;
    }

    /* =SLIDE ITEM
    -------------------------------------------------------------- */
    &__slide {
        position: relative;
        background-size: cover;
        background-position: center;
        text-align: right;
        overflow: hidden;

        @media #{$media-md} {
            max-height: 380px;
        }

        @media #{$media-sm-up} {
            height: 100%;
        }

        @media #{$media-xs} {
            background-position: bottom center;
            height: 100%;
        }

        @media screen and (max-width: 400px) {
            max-height: 238px;
        }

        a {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }
    }
}
