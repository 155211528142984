/* CMS POSTS CONTAINER
-------------------------------------------------------------- */
.homepage-cms {
    background-image: linear-gradient($body-bg, transparent), $background-pattern;
    background-image: linear-gradient($body-bg, transparent),
        image-set(
            $background-pattern-avif type('image/avif'),
            $background-pattern type('image/jpeg')
    );
    background-size: cover;
    padding: 24px 0;
    width: 100%;
    height: auto;
    will-change: transform;
}

.cms-posts {
    // custom row settings
    .row {
        @media #{$media-md-up} {
            margin-left: -25px;
            margin-right: -25px;
        }
    }

    // custom cols settings
    [class*='col-'] {
        @media #{$media-md-up} {
            padding-left: 25px;
            padding-right: 25px;
        }
    }

    &__title {
        padding-bottom: 60px;

        @media #{$media-xs} {
            padding-bottom: 20px;
        }

        h2 {
            font-weight: $font-weight-bold;

            @media #{$media-xs} {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

    // @extend global/components/link-arrow.scss
    .link-arrow {
        display: block;
        margin-top: 15px;
        color: $gray-dark;

        &:hover,
        &:focus {
            color: $blue-dianne;
        }
    }
}

/* MAIN POST
-------------------------------------------------------------- */
.cms-posts-main {
    background: $body-bg;
    border-radius: 8px;

    // cover
    &__img {
        position: relative;

        &:before {
            content: '';
            display: block;
            width: 100%;
            padding-top: 60%;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            object-position: center;
        }
    }

    // wrapper
    &__post {
        max-width: 400px;

        .cms-posts-main__author {
            @media #{$media-xs} {
                margin-bottom: 20px;
            }
        }

        a {
            span {
                font-size: 16px;
                line-height: 10px;
                font-weight: 600;
                color: $sherpa-blue;
            }

            .link-arrow__icon {
                margin-bottom: 0;
            }

            &:hover {
                span {
                    color: $dark-green;
                }

                .link-arrow__icon {
                    svg path {
                        stroke: $dark-green;
                    }
                }
            }
        }

        @media #{$media-xs} {
            max-width: 600px;
        }

        .cms-posts-main__author {
            @media #{$media-xs} {
                margin-bottom: 20px;
            }
        }

        a {
            span {
                font-size: 16px;
                font-weight: 600;
                color: $sherpa-blue;
            }

            .link-arrow__icon {
                margin-bottom: 0;
            }
        }

        @media #{$media-xs} {
            padding-top: 20px;
            margin: 0 15px;
        }
    }

    &__title {
        font-size: 24px;
        font-weight: 600;
        display: block;
        margin-bottom: 25px;
        line-height: 1.2;

        @media #{$media-xs} {
            font-size: 18px;
            line-height: 18px;
        }
    }

    &__author {
        &-img {
            min-width: 60px;

            img {
                width: 60px;
                height: 60px;
                border-radius: 50%;
            }
        }

        &-name {
            padding-left: 30px;
            width: 100%;
        }
    }

    &__teaser {
        margin-top: 15px;
        font-size: 14px;
        font-weight: 400;
    }
}

/* SMALL TEASERS
-------------------------------------------------------------- */
.cms-posts-teaser {
    $b: &;
    margin-top: 40px;
    margin-bottom: 25px;
    background: $body-bg;
    border-radius: 8px;

    &__cover {
        position: relative;
        margin-bottom: 20px;
        width: 100%;

        &:before {
            content: '';
            display: block;
            width: 100%;
            padding-top: 70%;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            object-position: center;
        }
    }

    &__wrap {
        padding-left: 10px;
        padding-right: 10px;

        @media #{$media-to-sm} {
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    &__title {
        line-height: 1.5;
        color: $gray-dark;
        font-weight: 500;

        #{$b}:hover & {
            color: $blue-dianne;
        }
    }
}
